<div id="header-container">
  <img id="klusfixr-header-logo" src="assets/img/KlusFixR_Final2_NoSubText.png" alt="KlusFixR">
  <div id="contact-navbar">
    <ul id="contact-list">
      <li><mat-icon>mail</mat-icon><a routerLink="/contact" class="contact-text">
        info@klusfixr.nl</a></li>
      <li><mat-icon>phone</mat-icon><a routerLink="/contact" class="contact-text">
        +31 621372938</a></li>
    </ul>
    <div id="navbar">
      <ul>
        <li class="navbar-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <a routerLink="">Home</a>
        </li>
        <li class="navbar-item" routerLinkActive="active"><a routerLink="/about">About</a></li>
        <li class="navbar-item" routerLinkActive="active"><a routerLink="/references">References</a></li>
        <li class="navbar-item" routerLinkActive="active"><a routerLink="/contact">Contact</a></li>
      </ul>
    </div>
  </div>
</div>

<div id="mobile-header-container">
  <mat-icon id="menu-icon" (click)="toggleMenu()">{{menuIcon}}</mat-icon>
  <img id="mobile-klusfixr-header-logo" src="assets/img/KlusFixR_Final2_NoSubText.png" alt="KlusFixR">
</div>
<div id="mobile-navbar" [class.active]="this.isToggled">
  <ul id="menu-content">
    <li class="mobile-navbar-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <a routerLink="" (click)="toggleMenu()">Home</a>
    </li>
    <li class="mobile-navbar-item" routerLinkActive="active"><a routerLink="/about" (click)="toggleMenu()">About</a></li>
    <li class="mobile-navbar-item" routerLinkActive="active"><a routerLink="/references" (click)="toggleMenu()">References</a></li>
    <li class="mobile-navbar-item" routerLinkActive="active"><a routerLink="/contact" (click)="toggleMenu()">Contact</a></li>
  </ul>
</div>
