import { Component, OnInit } from '@angular/core';
import {style} from '@angular/animations';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  menuIcon: string;
  isToggled: boolean;

  constructor() {
    this.menuIcon = 'menu';
    this.isToggled = false;
  }

  ngOnInit(): void {
  }

  toggleMenu(): void {
    if (this.isToggled) {
      this.menuIcon = 'menu';
      this.isToggled = !this.isToggled;
      return;
    }
    this.menuIcon = 'keyboard_arrow_left';
    this.isToggled = !this.isToggled;
  }

}
