<div id="contact-container">
  <div id="span-container">
    <span id="contact-info">
      <h3>KlusfixR</h3>
      <hr class="klusfixr-green-hr">
      Wilt u een mail sturen? Geen probleem!<br/>
      Stuur in dat geval een mail naar <a>info@klusfixr.nl</a>
      <br/>Liever bellen? Bel dan via <a>+31 621372938</a>
      <hr class="klusfixr-green-hr">
    </span>
  </div>
</div>
