<div id="body-container">
  <div id="projects-container">
    <div id="image-container">
      <img id="polaroids" src="../../assets/img/Project_Polaroids.png" alt="Projects">
    </div>
    <div id="projects-text-container">
      <span>
        KlusfixR biedt goede service en communicatie waarbij de werkzaamheden met de klant tot een goed einde worden gebracht.
        De klant wordt volledig ontzorgd waarbij het vertrouwen centraal staat.
        <br/>KlusfixR is een jong en dynamisch bedrijf wat samen met de klant wilt denken in mogelijkheden en oplossingen.
      </span>
      <hr class="klusfixr-green-hr">
    </div>
  </div>
  <div id="about-container">
    <span>
      Bij KlusfixR moet u zijn voor verbouwingen in en aan uw woning, dit kan het ophangen van een gordijnrail zijn,
      maar ook het plaatsen van een keuken of het afhangen van een deur.<br/>

      <br/>Ook denken we mee hoe mogelijke vraagstukken voor u opgelost kunnen worden.
      Als u geïnteresseerd bent, kunt u via mail of telefoon uw vraag stellen. De gegevens vindt u onder contact.<br/>

      <br/>Deze site zal de komende tijd nog verder worden uitgebreid en up to date gehouden worden.
    </span>
  </div>
</div>
